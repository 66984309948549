.bs-accordion {
    .accordion {
        .accordion-item {
            border: none;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            .accordion-header {
                .accordion-button {
                    // background-color: $clr-pink;
                    box-shadow: none;
                    border-bottom: 0;
                    display: flex;

                    .icon {
                        color: $clr-grey6;
                        font-size: 20px;
                        margin-right: 20px;
                    }

                    .acc-title {
                        font-size: 16px;
                        color: $clr-black;
                        font-weight: $font-bold;
                    }

                    &::after {
                        content: '-';
                        background-image: none;
                        font-size: 37px;
                        color: $clr-red3;
                        width: fit-content;
                        height: fit-content;
                        line-height: 0;
                    }

                    &.collapsed {

                        &::after {
                            content: '+';
                        }
                    }
                }
            }

            .accordion-collapse {
                // background-color: $clr-pink;



                &.collapse {
                    &.show {}
                }

                .accordion-body {
                    ul {
                        padding-left: 0px;
                        list-style-type: none;

                        li {
                            position: relative;
                            margin-bottom: 15px;
                            padding-left: 40px;
                            text-align: left;

                            &::before {
                                content: '';
                                width: 3px;
                                height: 3px;
                                background-color: $clr-black;
                                border-radius: 50%;
                                position: absolute;
                                left: 20px;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-accordion {
        .accordion {
            .accordion-item {

                &:last-child {}

                .accordion-header {
                    .accordion-button {

                        .icon {
                            font-size: 16px;
                            margin-right: 15px;
                        }

                        .acc-title {
                            font-size: 12px;
                        }

                        &::after {}

                        &.collapsed {

                            &::after {
                                font-size: 30px;
                            }
                        }
                    }
                }

                .accordion-collapse {

                    &.collapse {
                        &.show {}
                    }

                    .accordion-body {
                        .acc-body-title{
                            font-size: 12px;
                            text-align: left;
                        }
                        ul {

                            li {
                                font-size: 12px;
                                text-align: left;
                                padding-left: 30px;

                                &::before {
                                    top: 10px;
                                    left: 9px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}