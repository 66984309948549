.bs-btn {
    width: 100%;
    border: none;
    padding: 18px;
    background: $clr-radio-gradient;
    // border: 1px solid transparent;
    font-size: 20px;
    line-height: 1.0;
    transition: all 0.5s;
    color: $clr-white;
    text-decoration: none;
    display: inline-block;
    border-radius: 6px;

    &.typ-mb-on {
        margin-bottom: 25px;
    }

    &:hover {
        background: $clr-radio-gradient-reverse;
        // border:1px solid $clr-green !important;
        // color: $clr-green !important;
        color: $clr-white;
        text-decoration: none;
    }

    &.typ-fix-width {
        max-width: 486px;
        margin: 0 auto;
    }

    &.mt-25 {
        margin-top: 25px;
    }

    &.mob-mb-25 {
        margin-bottom: 25px;
    }

    &.typ-check-score {
        // margin-top: 20px;
    }

    &.typ-transparent{
        background : transparent;
        color: $clr-green;
        border: 1px solid $clr-green;
        font-size: 16px;
        padding: 12px 20px;
        &:focus-visible{
            outline: none;
        }
    }

    &.typ-med{
        max-width: 400px;
    }

    &:disabled {
        background: $clr-grey10;
    }

    &.mt-20{
        margin-top: 20px;
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-btn {
        font-size: 12px;

        &.typ-transparent{
            font-size: 12px;
            padding: 10px 15px;
        }

        &.typ-check-score>div {}

        &.typ-fix-width {
            // max-width: 314px;
        }
    }
}