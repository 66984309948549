.bs-table {
    background-color: $clr-light-green2;
    padding: 0 20px;

    table {
        thead {
            border-bottom: 2px solid $clr-light-blue;
        }

        tr {

            th,
            td {
                padding: 20px;
                text-align: center;
                font-size: 20px;
            }
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-table {

        table {
            thead {}

            tr {

                th,
                td {
                    padding: 10px;
                    font-size: 14px;
                    vertical-align: middle;
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: $mobile-breakpoint) {
    .bs-table {

        table {
            thead {}

            tr {

                th,
                td {
                    padding: 10px;
                    font-size: 12px;
                    a{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}