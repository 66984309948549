.bs-heart-plan-box {
    margin-top: 50px;
    margin-bottom: 30px;

    .top-header {
        &.tab-row {
            padding: 0px;
            border: 1px solid $clr-border;
            border-bottom: none;
            border-radius: 0;
            position: relative;

            .tab-col {
                padding-top: 50px;
                position: relative;

                .popular {
                    position: absolute;
                    left: 50%;
                    top: 0px;
                    transform: translate(-50%, -50%);
                }

                &-1 {
                    width: 50%;
                    text-align: left;
                }

                &-2 {
                    width: 25%;
                }

                &-3 {
                    width: 25%;
                }

                .title {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: $font-bold;
                    font-family: $fnt2;
                }

                .enrolled-count {
                    margin-bottom: 0;
                }
            }
        }
    }

    .bottom-footer {
        &.tab-row {
            padding: 0px 0px 50px;
            border: 1px solid $clr-border;
            border-top: none;
            border-radius: 0 0 20px 20px;

            .tab-col {
                &-1 {
                    width: 50%;
                    text-align: left;
                }

                &-2 {
                    width: 25%;
                }

                &-3 {
                    width: 25%;
                }

                .title {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: $font-bold;
                    font-family: $fnt2;
                }

                .enrolled-count {
                    margin-bottom: 0;
                }
            }
        }
    }

    .tab-row {
        display: flex;
        // align-items: center;
        // justify-content: flex-start;
        padding: 0px 0px;

        .tab-col {
            text-align: center;
            padding: 20px 40px;

            &-1 {
                width: 50%;
                text-align: left;
            }

            &-2 {
                width: 25%;
            }

            &-3 {
                width: 25%;
            }

            .title {
                margin-bottom: 0;
                font-size: 24px;
                font-weight: $font-bold;
                font-family: $fnt2;
            }

            .enrolled-count {
                margin-bottom: 0;
            }

            .plan-name {
                font-size: 20px;
                color: $clr-black2;
                margin-bottom: 0;
                max-width: 384px;
            }

            ul {
                margin-bottom: 0;

                li {
                    font-size: 20px;
                }
            }

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;

                &.icon-minus-table {
                    font-size: 22px;

                }
            }

            .price-box {
                display: flex;
                align-items: center;
                justify-content: center;

                .mrp {
                    font-size: 22px;
                    color: $clr-grey9;
                    margin-right: 15px;
                    text-decoration: line-through;
                    margin-bottom: 0;
                }

                .price {
                    font-size: 32px;
                    font-weight: $font-bold;
                    position: relative;
                    margin-bottom: 0;

                    &::before {
                        content: '₹';
                        font-size: 32px;
                    }
                }
            }

            .dicount {
                font-size: 20px;
                color: $clr-green;
                font-weight: $font-bold;
            }

            .bs-btn {
                width: 80%;
            }

            &.typ-green {
                background-color: $clr-light-green2;
            }

            &.typ-blue {
                background-color: $clr-light-blue3;
            }

            &.typ-pink {
                background-color: $clr-light-pink;
            }

            &.typ-orange {
                background-color: $clr-light-orange;
            }
        }

        &:nth-child(2){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-green2;
                }
            }
        }

        &:nth-child(3){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-green2;
                }
            }
        }


        &:nth-child(4){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-blue3;
                }
            }
        }


        &:nth-child(5){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-blue3;
                }
            }
        }


        &:nth-child(6){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-pink;
                }
            }
        }


        &:nth-child(7){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-pink;
                }
            }
        }


        &:nth-child(8){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-pink;
                }
            }
        }


        &:nth-child(9){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-orange;
                }
            }
        }


        &:nth-child(10){
            .tab-col{
                &.typ-green{
                    background-color: $clr-light-orange;
                }
            }
        }


        &.typ-green {
            background-color: $clr-light-green2;
        }

        &.typ-blue {
            background-color: $clr-light-blue3;
        }

        &.typ-pink {
            background-color: $clr-light-pink;
        }

        &.typ-orange {
            background-color: $clr-light-orange;
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-heart-plan-box {
        margin-top: 40px;

        .top-header {
            &.tab-row {

                .tab-col {
                    &-1 {}

                    &-2 {}

                    &-3 {}

                    .title {}

                    .enrolled-count {}
                }
            }
        }

        .bottom-footer {
            &.tab-row {

                .tab-col {
                    &-1 {

                    }

                    &-2 {
                        text-align: center;
                        padding: 20px 0;
                    }

                    &-3 {
                        padding: 20px 0;
                    }

                    .title {}

                    .enrolled-count {}
                }
            }
        }

        .tab-row {
            // padding: 20px 10px;

            .tab-col {

                &-1 {}

                &-2 {}

                &-3 {}

                .title {}

                .enrolled-count {}

                .plan-name {
                    font-size: 12px;
                }

                ul {

                    li {
                        font-size: 12px;
                    }
                }

                .icon {
                    font-size: 10px;

                    &.icon-minus-table {}
                }

                .price-box {

                    .mrp {
                        display: none;
                    }

                    .price {
                        font-size: 18px;

                        &::before {
                            font-size: 18px;
                        }
                    }
                }

                .dicount {
                    display: none;
                }

                .bs-btn {
                    width: 90%;
                    padding: 15px;
                }
            }

            &.typ-green {}

            &.typ-blue {}

            &.typ-pink {}

            &.typ-orange {}
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-heart-plan-box {
        .top-header {
            &.tab-row {

                padding: 0px 0px 20px;
                padding-bottom: 0;

                .popular {
                    position: absolute;
                    right: 10px;
                    top: -9px;
                    width: 70px;
                }

                .tab-col {
                    padding-top: 25px;

                    &-1 {}

                    &-2 {}

                    &-3 {}

                    .title {
                        font-size: 14px;
                    }

                    .enrolled-count {}
                }
            }
        }

        .bottom-footer {
            &.tab-row {
                padding: 20px 10px 20px;
                align-items: center;

                .tab-col {
                    padding: 20px 0;

                    &-1 {}

                    &-2 {}

                    &-3 {}

                    .title {
                        font-size: 16px;
                    }

                    .enrolled-count {}
                }
            }
        }

        .tab-row {
            padding: 0;

            .tab-col {
                padding: 20px 10px;

                &-1 {}

                &-2 {}

                &-3 {}

                .title {}

                .enrolled-count {}

                .plan-name {
                    font-size: 12px;
                }

                ul {

                    li {
                        font-size: 12px;
                    }
                }

                .icon {
                    font-size: 10px;

                    &.icon-minus-table {}
                }

                .price-box {

                    .mrp {
                        display: none;
                    }

                    .price {
                        font-size: 18px;

                        &::before {
                            font-size: 18px;
                        }
                    }
                }

                .dicount {
                    display: none;
                }

                .bs-btn {
                    width: 90%;
                    padding: 15px;
                }
            }

            &.typ-green {}

            &.typ-blue {}

            &.typ-pink {}

            &.typ-orange {}
        }
    }
}