* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $fnt2;
  background-color: $clr-lightblue;
  overflow-x: hidden;
  counter-reset: section;
}

picture {
  &.w-100 {
    display: inline-block;
    width: 100%;

    img {
      width: 100%;
    }

    &.mb-on {
      margin-bottom: 40px;
    }
  }
}

ul {
  &.counter-increment {
    list-style-type: none;
    margin-bottom: 0;

    li {
      margin-bottom: 20px;
      position: relative;
      font-size: 18px;

      &::before {
        counter-increment: section;
        content: counter(section);
        position: absolute;
        left: -30px;
        top: 2px;
        width: 23px;
        height: 23px;
        background-color: $clr-green;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $clr-white;
        border-radius: 50%;
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.sec-yt {
  margin-bottom: 50px;
}

.cm-next-arrow {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  width: 60px;
  z-index: 1;
  border: 0;
  width: 60px;
  height: 60px;
  background: linear-gradient(100.35deg, #F2C94C -5.12%, #F2994A 100%);
  border-radius: 20px 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.8s;

  &.disable {
    pointer-events: none;
    background: linear-gradient(100.35deg, rgba(192, 192, 192, 0.5) -5.12%, #BFBFBF 100%);
  }

  &:hover {
    background: linear-gradient(100.35deg, #F2994A -5.12%, #F2C94C 100%);
  }

  img {
    width: 100%;
  }

  &:target {
    // background: red;
  }
}

.cm-prev-arrow {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  width: 60px;
  z-index: 1;
  outline: none;
  border: none;
  width: 60px;
  height: 60px;
  background: linear-gradient(100.35deg, #F2C94C -5.12%, #F2994A 100%);
  border-radius: 0px 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s;

  .icon {
    transform: rotate(180deg);
  }

  &:hover {
    background: linear-gradient(100.35deg, #F2994A -5.12%, #F2C94C 100%);
  }

  img {
    width: 100%;
  }
}

.cm-show {
  display: block;
}

.cm-hide {
  display: none;
}

.row {
  &>.div {
    .row {
      &>.div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.cm-para {
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none;
  background: transparent;
  border: none;
  outline: none;

  &.mb-0 {
    margin-bottom: 0;
  }

  &.mb-40 {
    margin-bottom: 40px;
  }

  &.typ-inline {
    display: inline !important;
  }

  &.typ-form-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: $font-regular !important;

    &.typ-medium {
      font-weight: $font-medium !important;
    }

    &.typ-grey {
      font-size: 18px;
    }

    &.typ-small {
      font-size: 14px !important;
    }
  }

  &.typ-mt-30 {
    margin-top: 30px;
  }

  &.typ-grey6 {
    color: $clr-grey7;
  }

  &.typ-black {
    color: $clr-black;
  }

  &.typ-black2 {
    color: $clr-black2;
  }

  &.typ-grey {
    color: $clr-grey;
  }

  &.typ-medium {
    font-weight: $font-medium;
  }

  &.typ-bold {
    font-weight: $font-bold;
  }

  &.typ-small {
    font-size: 16px;
  }

  &.typ-max-wid {
    max-width: 90%;
    margin: 0 auto;

    &.mb-10 {
      margin-bottom: 10px;
    }

    &.typ-mt-30 {
      margin-top: 30px;
    }

    &.typ-form-title {
      margin-bottom: 15px;
    }
  }

  &.typ-center {
    text-align: center;
  }

  &.typ-underline {
    text-decoration: underline;
  }

  &.typ-link {
    // background: linear-gradient(90deg, #1E986D 5.19%, #14D893 98.11%, rgba(30, 152, 109, 0) 101.11%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    // text-fill-color: transparent;
    color: #14D893;
    width: 100%;
    display: inline-block;
    font-weight: $font-medium;
    // width: fit-content;
    position: relative;
    margin: 0 auto;

    // &::after{
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   width: 100%;
    //   height: 1px;
    //   background: linear-gradient(90deg, #1E986D 5.19%, #14D893 98.11%, rgba(30, 152, 109, 0) 101.11%);
    // }

    &:hover {
      color: #14D893;
      // background: linear-gradient(90deg, #1E986D 5.19%, #14D893 98.11%, rgba(30, 152, 109, 0) 101.11%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      // background-clip: text;
      // text-fill-color: transparent;
    }

    &:focus {
      color: #14D893;
      // background: linear-gradient(90deg, #1E986D 5.19%, #14D893 98.11%, rgba(30, 152, 109, 0) 101.11%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      // background-clip: text;
      // text-fill-color: transparent;
    }

    &:focus-visible {
      color: #14D893;
      // background: linear-gradient(90deg, #1E986D 5.19%, #14D893 98.11%, rgba(30, 152, 109, 0) 101.11%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      // background-clip: text;
      // text-fill-color: transparent;
    }
  }

  span,
  a,
  button {
    &.red {
      color: $clr-red;
      font-size: 16px;
      font-weight: $font-bold;
      text-decoration: none;
      display: inline-block;
      border: none;
      outline: none;
      background-color: transparent;
    }

    &.block {
      display: block;
    }
  }
}

.avatar-img-box {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.cm-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-medium;
  margin-top: 40px;

  .icon {
    font-size: 18px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    margin-top: -4px;
    padding: 0 15px;

    &.typ-rotate {
      transform: rotate(180deg);
    }

    &.typ-disable {
      color: $clr-grey5;
      pointer-events: none;
    }
  }

  .pagi-no {
    margin: 0 10px;
    margin-bottom: 0;
    font-size: 18px;
  }
}

.cm-selected-checks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  p {
    &.selected {
      border: 1px solid $clr-yellow;
      margin-bottom: 0;
      padding: 8px 10px;
      background-color: $clr-yellow-light;
      font-weight: $font-medium;
      font-size: 14px;
      color: $clr-yellow;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        margin-left: 5px;
        margin-bottom: 1px;
        cursor: pointer;
      }
    }
  }
}

.cm-img-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.embed-container {
  // --video--width: 1296;
  // --video--height: 540;

  position: relative;

  // padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
  /* 41.66666667% */
  // overflow: hidden;
  // max-width: 100%;
  // background: transparent;
  &>div {
    height: calc(100vh - 500px);
  }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  .modal-dialog {
    .modal-content {
      border: none;
    }
  }
}

.mid-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dicount-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .bs-card {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.back-btn {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  color: $clr-black2;
  line-height: 1;
  font-size: 16px;

  .icon {
    margin-right: 15px;
    color: $clr-black2;
    line-height: 1;
    font-size: 16px;
    margin-top: -3px;
  }
}

.c-pointer {
  cursor: pointer;
}

.iframe{
  margin-bottom: 20px;
}
.video-banner{
  max-width: 58.33333333%;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: $desktop-breakpoint) {
  .video-banner{
    max-width: 81%;
  }
  picture {
    &.w-100 {

      img {}

      &.mb-on {
        margin-bottom: 20px;
      }
    }
  }
}


@media only screen and (max-width: $tablet-breakpoint) {
  .video-banner{
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .embed-container {

    position: relative;

    &>div {
      height: calc(100vh - 77vh);
    }
  }

  .row {
    &.mob-mb-80 {
      margin-bottom: 100px;
    }
  }

  .back-btn {
    margin-bottom: 10px;
    font-size: 12px;

    .icon {
      margin-right: 5px;
      font-size: 12px;
      margin-top: -2px;
    }
  }

  .vimeo-player {
    margin-bottom: 20px;
  }

  .dicount-box {
    .bs-card {
      margin-bottom: 30px !important;

      &:last-child {}
    }
  }

  .sec-yt {
    margin-bottom: 20px;

    .col-md-12 {
      padding-left: 0;
      padding-right: 0;

      a {
        img {
          margin-bottom: 20px;
        }
      }
    }
  }


  .main {
    margin-bottom: 90px;

    &.no-mb {
      margin-bottom: 0;
    }
  }

  .cm-next-arrow {
    position: fixed;
    top: inherit;
    bottom: 0ch;
    right: 0;
    transform: translate(0%, 0%);
    width: 60px;
    z-index: 2;

    img {
      width: 100%;
    }
  }

  .cm-prev-arrow {
    position: fixed;
    top: inherit;
    bottom: 0;
    left: 0;
    transform: translate(0%, 0%);
    width: 60px;
    z-index: 2;

    img {
      width: 100%;
    }
  }

  .avatar-img-box {
    picture {
      img {}
    }
  }

  .sec-save-my-heart-plan {
    .col-md-12 {
      padding-left: 0;
      padding-right: 0;

      .bs-card {
        margin-bottom: 20px;
      }

      &:last-child {
        .bs-card {
          margin-bottom: 0px;
        }
      }
    }
  }

  ul {
    &.counter-increment {
      margin-bottom: 50px;

      li {
        font-size: 14px;

        &::before {
          width: 20px;
          height: 20px;
        }

        &:last-child {}
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {

  .iframe{
    height: 200px;
  }

  .row {
    &.mob-mb-80 {
      margin-bottom: 80px;
    }
  }

  .vimeo-player {
    margin-bottom: 20px;
  }

  .sec-yt {
    margin-bottom: 20px;

    .col-md-12 {
      padding-left: 0;
      padding-right: 0;

      a {
        img {
          margin-bottom: 20px;
        }
      }
    }
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .cm-pagination {
    font-size: 14px;

    .icon {
      font-size: 14px;

      &.typ-rotate {}

      &.typ-disable {}
    }

    .pagi-no {
      font-size: 14px;
    }
  }

  .main {
    margin-bottom: 80px;

    &.no-mb {
      margin-bottom: 0;
    }
  }

  .avatar-img-box {
    picture {

      // width: 100%;
      img {}
    }
  }

  .cm-para {
    font-size: 13px;
    margin-bottom: 20px;

    &.typ-bold {
      font-weight: $font-bold;
    }

    &.typ-center {
      text-align: center;
    }

    &.typ-small {
      font-size: 12px;
    }

    &.typ-mob-left {
      text-align: left;
    }

    &.typ-form-title {
      font-size: 14px;

      &.typ-small {
        font-size: 13px !important;
      }

      &.typ-grey {
        font-size: 12px !important;
      }
    }

    &.typ-max-wid {
      max-width: 100%;
      font-size: 14px;

      &.typ-mt-30 {
        margin-top: 15px;
      }
    }
  }

  .cm-selected-checks {
    display: inline-block;
    overflow: auto;
    white-space: nowrap;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    p {
      &.selected {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }

  .only-desk {
    display: none;
  }

  .sticky-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    top: inherit;
    transform: translate(-50%, -0%);
    display: inline-block;
    padding: 20px;
    padding-top: 0;
  }

  ul {
    &.counter-increment {
      margin-bottom: 50px;

      li {
        font-size: 14px;

        &::before {
          width: 20px;
          height: 20px;
          font-size: 12px;
        }

        &:last-child {}
      }
    }
  }

  .sec-save-my-heart-plan {
    .col-md-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .bs-card {
        margin-bottom: 0px;
        padding-bottom: 0;

        &.mob-space-on {
          margin-bottom: 15px;
          padding-bottom: 15px;
        }
      }

      &:last-child {
        .bs-card {
          margin-bottom: 0px;
        }
      }
    }
  }

  .cm-img-col {
    &.typ-madhav-baugpopup {
      margin-bottom: 20px;

      a {
        width: 100%;
        display: inline-block;

        img {
          width: 100%;
        }
      }
    }
  }
}