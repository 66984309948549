.bs-lottifiles {
    text-align: center;
    position: relative;

    .multi-color-bg {
        width: 350px;
        position: relative;
        z-index: 1;
    }

    .band-girl {
        position: absolute;
        top: -20%;
        // top: 10%;
        left: 50%;
        width: 150px;
        transform: translate(-50%, -50%);
    }

    .lotti-content-box {
        position: absolute;
        left: 50%;
        bottom: 15px;
        width: 130px;
        transform: translate(-50%, -0%);
        z-index: 3;

        .result-txt {
            margin-bottom: 0;
            font-size: 12px;
            color: $clr-red2;
            font-weight: $font-medium;
            text-transform: uppercase;
            line-height: 1;
            margin-bottom: 5px;
        }

        .result-no {
            margin-bottom: 0;
            font-size: 28px;
            font-weight: $font-bold;
            line-height: 1;
        }
    }

    &.typ-animation {

        .lf-player-container {
            width: 85%;
            margin: 0 auto;

            #lottie {
                background-image: url('../../images/lottie-bg.png') !important;
                background-size: contain !important;
                background-repeat: no-repeat !important;
            }
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-lottifiles {
        margin-top: 0px;
        margin-bottom: 25px;

        &.typ-healthscore {
            margin-top: 100px;
            width: 100%;
        }

        .lotti-content-box {
            .result-txt {}

            .result-no {}
        }

        &.typ-mob-no-space {
            margin-top: 0;
        }

        &.typ-animation {
    
            .lf-player-container {
                width: 100%;
    
                #lottie {
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-lottifiles {
        width: 75%;
        margin: 0 auto;
        margin-bottom: 20px;

        &.typ-healthscore {
            margin-top: 100px;
            width: 100%;
        }

        .multi-color-bg {
            width: 250px;
        }

        .lotti-content-box {
            bottom: 10px;

            .result-txt {
                margin-bottom: 5px;
            }

            .result-no {
                font-size: 26px;
            }
        }

        &.typ-mob-no-space {
            margin-top: 0;
        }
    }
}