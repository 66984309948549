.bs-form {
    margin-top: 0px;

    .radio-box {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0 !important;
        gap: 20px;
        width: 100%;

        .form-check {
            padding-left: 0;
            margin-bottom: 0;
            min-height: inherit;

            .form-check-label {
                position: relative;
                width: 100%;

                input {
                    display: none;

                    &:checked+div.radio-label {
                        background: $clr-radio-gradient;
                        // border: 1px solid #1D976C;
                        color: $clr-white;
                        position: relative;

                        .right-icon {
                            display: none;
                            // position: absolute;
                            // top: 1px;
                            // right: 1px;
                            // font-size: 16px;
                            // color: #595454;
                            // width: 50px;
                            // transition: all 0.3s;
                            // border-radius: 6px;
                        }

                        i {
                            &.language {
                                color: $clr-white;
                            }
                        }
                    }
                }

                div.radio-label {
                    border-radius: 8px;
                    background: $clr-white;
                    transition: all 0.3s;
                    border: 1px solid $clr-light-blue;
                    padding: 15px;
                    color: $clr-black;
                    display: block;
                    min-width: 220px;
                    font-family: $fnt2;
                    font-size: 20px;
                    overflow: hidden;
                    cursor: pointer;

                    i {
                        &.language {
                            font-style: normal;
                            margin-right: 15px;
                            font-size: 18px;
                            color: $clr-grey;
                            transition: all 0.3s;
                        }
                    }

                    .right-icon {
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: 16px;
                        color: #595454;
                        width: 0px;
                        transition: all 0.3s;
                    }

                    &:hover {
                        background: #93F9B9;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.typ-gender {
            margin-bottom: 40px !important;

            &.mb-0 {
                margin-bottom: 0 !important;
            }

            .form-check {
                width: 100%;
                margin-right: 0;

                .form-check-label {
                    width: 100%;

                    input {

                        &:checked+div.radio-label {

                            .icon {
                                color: $clr-white;

                                &::before {
                                    color: $clr-white;
                                }
                            }

                            &::before {
                                background-color: $clr-white;
                            }

                            &::after {
                                color: #595454;
                            }
                        }
                    }

                    .radio-label {
                        border-color: $clr-light-blue;
                        text-align: center;
                        font-size: 18px;
                        padding: 20px;
                        overflow: hidden;
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            right: -50px;
                            top: -60px;
                            width: 100px;
                            height: 100px;
                            transform: rotate(40deg);
                            background-color: $clr-light-blue2;
                        }

                        &::after {
                            content: "\e901";
                            font-family: icomoon;
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            font-size: 12px;
                            color: #C2C2C2;
                        }

                        .icon {
                            font-size: 18px;
                            margin-right: 15px;
                            color: $clr-black;

                            &::before {
                                color: $clr-black;
                            }
                        }

                        &:hover {}
                    }
                }
            }
        }

        &.typ-timeline {
            flex-wrap: nowrap;
            width: 100%;
            position: relative;

            .form-check {
                margin-right: 0;
                width: 100%;
                text-align: center;
                margin-bottom: 0;

                .form-check-label {
                    .check-box-label {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        cursor: pointer;

                        .circle-box {
                            min-height: 40px;

                            .circle {
                                width: 22px;
                                height: 22px;
                                background-color: transparent;
                                border: 4px solid $clr-light-blue;
                                border-radius: 50%;
                                position: relative;
                                margin-bottom: 15px;
                                transition: all 0.3s;
                                position: relative;

                                &::before {
                                    content: '\e901';
                                    font-family: 'icomoon';
                                    font-size: 10px;
                                    color: transparent;
                                    position: absolute;
                                    left: 50%;
                                    top: 51%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }

                        .radio-txt {
                            word-break: keep-all;
                            text-align: center;
                            width: 60px;
                            margin-bottom: 0;
                            line-height: 1.2;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            color: $clr-black2;
                        }

                        &:hover {
                            .circle {
                                background-color: #93F9B9;
                                border-color: #93F9B9;
                            }
                        }
                    }

                    input {

                        &:checked+div.check-box-label {

                            .circle-box {
                                .circle {
                                    background: $clr-radio-gradient;
                                    border: none;
                                    width: 27px;
                                    height: 27px;
                                    margin-top: -2px;

                                    &::before {
                                        color: $clr-white;
                                    }
                                }
                            }

                            .radio-txt {
                                font-weight: $font-medium;
                                color: $clr-black;
                            }
                        }
                    }
                }

                &.last-check {

                    .form-check-label {
                        .check-box-label {

                            .circle {

                                &::after {
                                    display: none;
                                }
                            }

                            .radio-txt {}
                        }
                    }
                }
            }

            // &::before {
            //     content: "";
            //     position: absolute;
            //     top: 8px;
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            //     width: calc(100% - 120px);
            //     height: 2px;
            //     background-color: $clr-light-blue;
            // }
        }

        &.typ-normal {
            display: flex;
            justify-content: center;

            .form-check {
                margin-right: 0;

                .form-check-label {
                    width: inherit;

                    input {

                        &:checked+div.radio-label {
                            background: transparent;
                            border: none;
                            color: $clr-black;

                            .circle {
                                &::before {
                                    content: '';
                                    width: 18px;
                                    height: 18px;
                                    background: $clr-radio-gradient;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    border-radius: 50%;
                                }
                            }
                        }
                    }

                    .radio-label {
                        background: transparent;
                        border: none;
                        padding: 0%;
                        min-width: inherit;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        min-width: 90px;

                        .circle {
                            width: 24px;
                            height: 24px;
                            border: 1px solid $clr-light-blue;
                            display: inline-block;
                            border-radius: 50%;
                            margin-right: 15px;
                            position: relative;
                        }
                    }

                    &:hover {
                        .radio-label {
                            background-color: transparent;
                        }

                        input {

                            &:checked+div.radio-label {

                                .circle {
                                    background-color: transparent;

                                    &::before {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }

                        .circle {
                            background-color: #93F9B9;
                        }
                    }
                }
            }
        }
    }

    .text-box {
        margin-bottom: 40px !important;

        .form-label {
            color: $clr-black2;
            font-size: 16px;
            margin-bottom: 8px;
        }

        .form-control {
            padding: 14px 15px;
            border: 1px solid $clr-light-blue;
            font-weight: $font-medium;
            border-radius: 10px;
            color: $clr-black2;
            opacity: 1;
            font-size: 16px;

            &::placeholder {
                color: $clr-black2;
                opacity: 0.6;
                font-size: 16px;
                font-weight: $font-regular;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type=number] {
                -moz-appearance: textfield;
            }
        }

        &:last-child {
            margin-bottom: 0 !important;
        }

        .input-with-btn {
            position: relative;

            .icon {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0%, -50%);
                height: 100%;
                aspect-ratio: 1/1;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(100.35deg, rgba(192, 192, 192, 0.5) -5.12%, #BFBFBF 100%);
                border-radius: 10px;
                cursor: pointer;

                &.active {
                    background: $clr-radio-gradient;
                }
            }
        }
    }

    .check-box {
        margin-bottom: 0;

        .form-check {
            .form-check-label {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                cursor: pointer;

                input {
                    display: none;

                    &:checked+.check-box-label {
                        .icon {
                            background: $clr-radio-gradient;
                            color: $clr-white;
                            border: none;

                            &::before {
                                color: $clr-white;
                            }
                        }
                    }
                }

                .check-box-label {
                    display: flex;
                    align-items: center;

                    .icon {
                        width: 20px;
                        height: 20px;
                        color: transparent;
                        background: $clr-white;
                        border: 1px solid $clr-green;
                        transition: all 0.3s;
                        aspect-ratio: 1/1;
                        font-size: 8px;
                        padding: 5px;
                        border-radius: 5px;
                        margin-right: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &::before {
                            color: transparent;
                        }
                    }
                }

                .check-txt {
                    display: flex;
                    align-items: center;

                    .cm-para {
                        font-size: 14px;
                        display: contents;
                    }
                }
            }
        }

        &.typ-btn {
            display: flex;
            flex-wrap: wrap;

            &.form-check {
                padding-left: 0;
                margin-bottom: 0;
                min-height: inherit;
                width: 47%;

                .form-check-label {
                    input {
                        display: none;

                        &:checked+div.checkbox-label {
                            background: $clr-radio-gradient;
                            // border: 1px solid #1D976C;
                            color: $clr-white;
                            position: relative;
                            // border-color: transparent;

                            &::before {
                                background-color: $clr-white;
                            }

                            &::after {
                                color: #595454;
                            }
                        }
                    }

                    div.checkbox-label {
                        border-radius: 8px;
                        background: $clr-white;
                        transition: all 0.3s;
                        border: 1px solid $clr-light-blue;
                        padding: 15px;
                        padding-right: 20px;
                        color: $clr-black;
                        display: block;
                        min-width: 220px;
                        font-family: $fnt2;
                        font-size: 16px;
                        overflow: hidden;
                        cursor: pointer;
                        line-height: 1;
                        overflow: hidden;
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            right: -50px;
                            top: -60px;
                            width: 90px;
                            height: 90px;
                            transform: rotate(40deg);
                            background-color: $clr-light-blue2;
                        }

                        &::after {
                            content: "\e901";
                            font-family: icomoon;
                            position: absolute;
                            right: 8px;
                            top: 8px;
                            font-size: 10px;
                            color: #C2C2C2;
                        }

                        &:hover {
                            background-color: #93F9B9;
                        }

                        .right-icon {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.typ-popup {
            &.form-check {
                padding-left: 0;
                width: 100%;
                margin-bottom: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .form-check-label {
                    width: 100%;

                    input {
                        display: none;

                        &:checked+div.checkbox-label {
                            border-color: $clr-green;

                            .icon {
                                color: $clr-white;
                                background: $clr-radio-gradient;

                                &::before {
                                    color: $clr-white;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                    }

                    div {
                        &.checkbox-label {
                            width: 100%;
                            padding: 15px;
                            border: 1px solid $clr-light-blue;
                            border-radius: 8px;
                            width: 100%;
                            position: relative;
                            margin-bottom: 15px;

                            .icon {
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 8px;
                                transition: all 0.3s;
                                background: transparent;
                                color: transparent;
                                padding: 5px;
                                border: 1px solid $clr-light-blue;
                                border-radius: 4px;
                                aspect-ratio: 1/1;
                                width: 25px;
                                height: 25px;

                                &::before {
                                    color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.typ-patients {

        .radio-box {

            .form-check {
                margin-right: 0;
                width: 100%;

                .form-check-label {
                    width: 100%;

                    input {

                        &:checked+div {
                            &.radio-label {
                                background: $clr-radio-gradient;

                                .patient-type-img {}

                                .patient-content {
                                    .patient-type {}

                                    .desc {
                                        color: $clr-white;
                                    }
                                }
                            }
                        }
                    }

                    div {
                        &.radio-label {
                            display: flex;
                            align-items: center;
                            padding: 20px 15px;
                            width: 100%;
                            background: $clr-white;

                            .patient-type-img {
                                width: 80px;
                                height: 80px;
                                margin-right: 15px;
                            }

                            .patient-content {
                                .patient-type {
                                    font-size: 26px;
                                    font-family: $fnt1;
                                    margin-bottom: 5px;
                                }

                                .desc {
                                    color: $clr-grey3;
                                    margin-bottom: 0;
                                    font-size: 18px;
                                }
                            }

                            &:hover {
                                background-color: #93F9B9;
                            }
                        }
                    }
                }

                &:last-child {}
            }
        }
    }

    .form-picker {
        .picker-container {
            .picker-inner {
                padding-left: 0;
                padding-right: 0;
                justify-content: space-between;

                .picker-column {
                    width: 45%;
                    flex: inherit;

                    .picker-scroller {

                        // overflow: scroll;
                        .picker-item {
                            cursor: pointer;
                            font-size: 24px;

                            // color: ;
                            // min-height: 50px;
                            &.picker-item-selected {
                                // border-top: 1px solid $clr-light-blue;
                                // border-bottom: 1px solid $clr-light-blue;
                                font-size: 32px;
                                font-weight: $font-bold;
                                color: $clr-black;
                            }
                        }
                    }

                    &:first-child {
                        .picker-scroller {
                            .picker-item {
                                &.picker-item-selected {
                                    &::after {
                                        margin-left: 5px;
                                        content: 'ft';
                                        font-size: 24px;
                                        font-weight: $font-medium;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(2) {
                        .picker-scroller {
                            .picker-item {
                                &.picker-item-selected {
                                    &::after {
                                        margin-left: 5px;
                                        content: 'in';
                                        font-size: 24px;
                                        font-weight: $font-medium;
                                    }
                                }
                            }
                        }
                    }
                }

                &::after {
                    content: "";
                    width: 10%;
                    height: 100%;
                    position: absolute;
                    background: $clr-white;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, -0%);
                }
            }
        }

        &.typ-single {
            .picker-container {
                .picker-inner {
                    justify-content: center;

                    .picker-column {

                        .picker-scroller {
                            .picker-item {
                                &.picker-item-selected {}
                            }
                        }

                        &:first-child {
                            .picker-scroller {
                                .picker-item {
                                    &.picker-item-selected {
                                        &::after {}
                                    }
                                }
                            }
                        }

                        &:nth-child(2) {
                            .picker-scroller {
                                .picker-item {
                                    &.picker-item-selected {
                                        &::after {}
                                    }
                                }
                            }
                        }
                    }

                    .picker-highlight {
                        &::before {
                            top: 0;
                            left: 50%;
                            width: 45%;
                            transform: translate(-50%, -50%);
                        }

                        &::after {
                            bottom: 0;
                            left: 50%;
                            width: 45%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    &::after {
                        content: inherit;
                    }
                }
            }
        }

        &.typ-weight {
            .picker-container {
                .picker-inner {

                    .picker-column {

                        .picker-scroller {
                            .picker-item {
                                &.picker-item-selected {
                                    &::after {
                                        content: 'kg';
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.no-active-txt {
            .picker-container {
                .picker-inner {

                    .picker-column {

                        &:first-child {
                            .picker-scroller {
                                .picker-item {
                                    &.picker-item-selected {
                                        &::after {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        &:nth-child(2) {
                            .picker-scroller {
                                .picker-item {
                                    &.picker-item-selected {
                                        &::after {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.typ-blood-pressure {
            .picker-container {
                .picker-inner {

                    &::before {
                        content: '/';
                        width: 10%;
                        height: 100%;
                        position: absolute;
                        background: $clr-white;
                        left: 50%;
                        top: 0;
                        transform: translate(-50%, 0%);
                        font-size: 30px;
                        color: $clr-black;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                    }

                    .picker-column {
                        &:first-child {
                            &::before {
                                // content: 'Systolic (Upper BP)';
                            }
                        }
                    }
                }
            }
        }
    }

    &.typ-otp {

        >div {
            width: 100%;
            justify-content: center;
            gap: 0 15px;

            .otp-input {
                margin-bottom: 25px;

                input {
                    border-radius: 8px;
                }
            }
        }
    }

    &.mb-15 {
        margin-bottom: 15px;
    }

    input {
        outline: none;
        border: 1px solid $clr-light-blue;

        &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $clr-light-blue;
        }
    }

    &.typ-range {
        width: 100%;
        margin-bottom: 40px;

        .rangeslider {
            height: 6px;
            max-width: 98%;
            background: $clr-light-blue;
            box-shadow: none;
            margin-top: 50px;

            .rangeslider__fill {
                background: $clr-radio-gradient;
            }

            .rangeslider__handle {
                background: $clr-radio-gradient;
                border: none;

                &::after {
                    display: none;
                }

                &:focus-visible {
                    outline: none;
                }

                .rangeslider__handle-label {
                    margin-top: -30px;
                    text-align: center;
                    font-weight: $font-bold;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
            }

            .rangeslider__labels {
                margin-top: 5px;
                padding-left: 0;
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;


                .rangeslider__label-item {
                    font-size: 16px;
                    color: $clr-grey6;
                    position: relative;
                    transform: translate3d(0, 0, 0);
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    &:first-child {
                        left: inherit !important;
                    }

                    &:last-child {
                        left: inherit !important;
                    }
                }
            }
        }

        .cm-para {
            padding-top: 25px;
        }

        &:first-child {
            .cm-para {
                padding-top: 0px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.typ-form-check {
        &>div {
            height: 70vh;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &.typ-auto-height {
            &>div {
                height: fit-content;

                &::-webkit-scrollbar {}
            }
        }
    }

    &.mt-0 {
        margin-top: 0;
    }

    &.mb-30 {
        margin-bottom: 30px;
    }

    &.typ-list {
        border: 1px solid $clr-border;
        padding: 30px 15px 15px 15px;
        border-radius: 8px;
        max-width: 600px;
        margin: 0 auto;

        .radio-box {
            flex-direction: column;

            .form-check {
                .form-check-label {
                    padding: 15px;
                    border-radius: 8px;
                    border: 1px solid $clr-green;

                    .check-box-label {
                        flex-direction: row-reverse;
                        align-items: center;
                        justify-content: space-between;

                        .circle-box {
                            min-height: inherit;
                            margin-left: 0px;
                            min-width: 27px;
                            min-height: 27px;

                            .circle {
                                margin-bottom: 0;
                            }
                        }

                        .radio-txt {
                            width: auto;
                        }
                    }
                }
            }
        }

        &.typ-list-modify {
            max-width: inherit;
            width: 100%;

            .radio-box {
                flex-direction: row;
                flex-wrap: wrap;

                .form-check {
                    width: 48%;

                    .form-check-label {
                        .check-box-label {
                            .radio-txt {
                                flex-direction: row;

                                .icon {
                                    color: $clr-green;
                                    margin-right: 10px;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.typ-coupon-code {
        .text-box {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            label {
                margin-bottom: 0;
                line-height: 1;
                margin-right: 15px;
            }

            input {
                max-width: 280px;
                font-weight: $font-semibold;
                margin-right: 15px;
                line-height: 1;
            }

            .bs-btn {
                max-width: 150px;
            }
        }
    }

    .small {
        color: $clr-green;
        margin-top: 5px;
        display: inline-block;
        font-size: 16px;
        text-decoration: underline;
    }
}

.error-message {
    font-size: 12px;
    color: $clr-red2;
    margin-bottom: 0;
    margin-top: 5px;

    &.typ-desk-center {
        text-align: center;
    }
}

@media only screen and (max-width: $desktop-breakpoint) {
    .bs-form {

        .radio-box {

            .form-check {

                .form-check-label {
                    input {

                        &:checked+div.radio-label {

                            .right-icon {
                                width: 55px;
                            }
                        }
                    }

                    div.radio-label {
                        min-width: 180px;
                        padding: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-form {

        .radio-box {
            justify-content: center;

            .form-check {

                .form-check-label {
                    input {

                        &:checked+div {

                            .right-icon {}
                        }
                    }

                    div {}
                }
            }
        }

        &.typ-patients {

            .radio-box {

                .form-check {

                    .form-check-label {
                        input {

                            &:checked+div {
                                &.radio-label {

                                    .patient-type-img {}

                                    .patient-content {
                                        .patient-type {}

                                        .desc {}
                                    }
                                }
                            }
                        }

                        div {
                            &.radio-label {
                                border-color: $clr-light-blue;

                                .patient-type-img {}

                                .patient-content {
                                    .patient-type {
                                        font-size: 20px;
                                    }

                                    .desc {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }

                    &:last-child {}
                }
            }
        }

        &.typ-list {

            &.typ-list-modify {
                padding-top: 15px;

                .radio-box {
                    flex-direction: column;

                    .form-check {
                        width: 100%;

                        .form-check-label {
                            .check-box-label {
                                .radio-txt {
                                    text-align: left;

                                    .icon {}
                                }
                            }
                        }
                    }
                }
            }
        }

        &.typ-coupon-code {
            .text-box {

                label {}

                input {
                    max-width: 180px;
                }

                .bs-btn {}
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-form {
        margin-top: 10px;

        &.typ-coupon-code {
            .text-box {
                justify-content: space-between;
                flex-wrap: wrap;

                label {
                    width: fit-content;
                    margin-bottom: 15px;
                    margin-right: 5px;
                    font-size: 12px;
                }

                input {
                    max-width: inherit;
                    width: 50%;
                    margin-bottom: 15px;
                    margin-right: 0;
                }

                .bs-btn {
                    width: 100%;
                    max-width: inherit;
                }
            }
        }

        .text-box {
            margin-bottom: 20px !important;

            .form-label {
                font-size: 14px;
            }

            .form-control {
                padding: 10px;
                border: 1px solid $clr-light-blue;
                border-radius: 4px;
                color: $clr-black2;
                font-size: 12px;

                &::placeholder {
                    color: $clr-black2;
                    font-size: 12px;
                }
            }

            &:last-child {
                margin-bottom: 0 !important;
            }
        }

        .check-box {
            .form-check {
                padding-left: 0;

                .form-check-label {
                    font-size: 14px;
                    text-align: left;
                    align-items: flex-start;
                    margin-bottom: 0;

                    input {
                        &:checked+.check-box-label {
                            .icon {}
                        }
                    }

                    .check-box-label {
                        .icon {}
                    }

                    .check-txt {
                        .cm-para {
                            font-size: 13px;
                        }
                    }
                }
            }

            &.typ-btn {

                &.form-check {
                    width: 48%;
                    margin-right: 0;
                    margin-bottom: 0;

                    .form-check-label {
                        input {

                            &:checked+div.checkbox-label {
                                width: 100%;
                                min-width: inherit;

                                .right-icon {
                                    width: 40px;
                                }
                            }
                        }

                        div.checkbox-label {
                            width: 100%;
                            min-width: inherit;
                            min-height: inherit;
                            display: flex;
                            align-items: center;
                            word-break: break-word;
                            line-height: 1.2;
                            font-size: 14px;
                            text-align: left;

                            .right-icon {}
                        }
                    }

                    &:last-child {}
                }
            }

            &.typ-popup {
                &.form-check {

                    &:last-child {}

                    .form-check-label {

                        input {

                            &:checked+div.checkbox-label {

                                .icon {

                                    &::before {}
                                }
                            }
                        }

                        div {
                            &.checkbox-label {

                                .icon {
                                    width: 20px;
                                    height: 20px;
                                    font-size: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .radio-box {
            justify-content: flex-start;
            gap: 10px;

            .form-check {
                margin-right: 0px;
                margin-bottom: 0px;
                min-width: inherit;
                width: 47%;
                text-align: center;

                &:nth-child(even) {
                    margin-right: 0;
                }

                .form-check-label {
                    width: 100%;

                    input {

                        &:checked+div.radio-label {
                            font-size: 14px;
                            width: 100%;

                            i {
                                &.language {
                                    font-size: 14px;
                                }
                            }

                            .right-icon {
                                width: 40px;
                            }
                        }
                    }

                    div.radio-label {
                        width: 100%;
                        min-width: inherit;
                        font-size: 14px;
                        padding: 15px 10px;

                        i {
                            &.language {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            &.typ-gender {
                .form-check {
                    width: 47%;

                    .form-check-label {
                        input {

                            &:checked+div.radio-label {
                                font-size: 16px;
                                // border: none;

                                i {
                                    &.language {}
                                }
                            }
                        }

                        .radio-label {
                            font-size: 16px;
                            padding: 25px 20px;
                            border-radius: 10px;

                            &::before {
                                width: 90px;
                                height: 90px;
                            }

                            &::after {
                                right: 7px;
                                top: 7px;
                                font-size: 10px;
                            }
                        }
                    }
                }
            }

            &.typ-timeline {
                flex-wrap: wrap;
                justify-content: center;

                .form-check {
                    width: 30%;
                    margin-bottom: 10px;

                    .form-check-label {
                        .check-box-label {

                            .circle-box {
                                min-height: 35px;

                                .circle {
                                    margin-bottom: 10px;
                                    width: 20px;
                                    height: 20px;
                                }
                            }

                            .radio-txt {
                                font-size: 11px;
                            }
                        }

                        input {

                            &:checked+div.check-box-label {

                                .circle-box {
                                    .circle {
                                        width: 22px;
                                        height: 22px;
                                        margin-top: 0;

                                        &::before {
                                            font-size: 7px;
                                        }

                                        &::after {}
                                    }
                                }
                            }
                        }
                    }

                    &.last-check {

                        .form-check-label {
                            .check-box-label {

                                .circle {

                                    &::after {}
                                }

                                .radio-txt {}
                            }
                        }
                    }
                }

                &::before {
                    width: calc(100% - 70px);
                }
            }

            &.typ-normal {
                justify-content: flex-start;

                .form-check {
                    width: fit-content;
                    text-align: left;

                    .form-check-label {
                        .radio-label {
                            text-align: left;
                            justify-content: flex-start;
                        }
                    }
                }
            }

            &.typ-disease {
                .check-box {
                    &.typ-btn {
                        &.form-check {
                            .form-check-label {
                                div.checkbox-label {
                                    min-height: 71px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.typ-form-check>div {
            height: 70vh;
            overflow-y: scroll;
        }

        .form-picker {
            .picker-container {
                .picker-inner {

                    .picker-column {

                        .picker-scroller {
                            .picker-item {
                                font-size: 14px;

                                &.picker-item-selected {
                                    font-size: 16px;

                                    &::after {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }

                        &:first-child {
                            .picker-scroller {
                                .picker-item {
                                    &.picker-item-selected {
                                        &::after {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }

                        &:nth-child(2) {
                            .picker-scroller {
                                .picker-item {
                                    &.picker-item-selected {
                                        &::after {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &::after {}
                }
            }

            &.typ-single {
                .picker-container {
                    .picker-inner {

                        .picker-column {

                            .picker-scroller {
                                .picker-item {
                                    &.picker-item-selected {}
                                }
                            }

                            &:first-child {
                                .picker-scroller {
                                    .picker-item {
                                        &.picker-item-selected {
                                            &::after {}
                                        }
                                    }
                                }
                            }

                            &:nth-child(2) {
                                .picker-scroller {
                                    .picker-item {
                                        &.picker-item-selected {
                                            &::after {}
                                        }
                                    }
                                }
                            }
                        }

                        .picker-highlight {
                            &::before {}

                            &::after {}
                        }

                        &::after {
                            content: inherit;
                        }
                    }
                }
            }

            &.typ-weight {
                .picker-container {
                    .picker-inner {

                        .picker-column {

                            .picker-scroller {
                                .picker-item {
                                    &.picker-item-selected {
                                        &::after {
                                            content: 'kg';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.no-active-txt {
                .picker-container {
                    .picker-inner {

                        .picker-column {

                            &:first-child {
                                .picker-scroller {
                                    .picker-item {
                                        &.picker-item-selected {
                                            &::after {}
                                        }
                                    }
                                }
                            }

                            &:nth-child(2) {
                                .picker-scroller {
                                    .picker-item {
                                        &.picker-item-selected {
                                            &::after {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.typ-blood-pressure {
                .picker-container {
                    .picker-inner {

                        &::before {}

                        .picker-column {
                            &:first-child {
                                &::before {}
                            }
                        }
                    }
                }
            }
        }

        &.typ-range {

            .rangeslider {
                width: 100%;
                margin-top: 30px;

                .rangeslider__fill {}

                .rangeslider__handle {
                    width: 24px;
                    height: 24px;

                    &::after {}

                    &:focus-visible {}

                    .rangeslider__handle-label {
                        font-size: 12px;
                        margin-top: -20px;
                    }
                }

                .rangeslider__labels {

                    .rangeslider__label-item {
                        font-size: 12px;
                    }
                }
            }

            .cm-para {}

            &:first-child {
                .cm-para {}
            }
        }

        &.typ-otp {

            >div {
                justify-content: flex-start;

                .otp-input {

                    input {}
                }
            }
        }

        &.typ-patients {

            .radio-box {

                .form-check {

                    &:nth-child(even) {}

                    .form-check-label {

                        input {

                            &:checked+div.radio-label {

                                i {
                                    &.language {}
                                }

                                .right-icon {}
                            }
                        }

                        div.radio-label {
                            text-align: left;

                            i {
                                &.language {}
                            }

                            .patient-type-img {
                                margin-right: 20px;
                            }

                            .patient-content {
                                .patient-type {
                                    font-size: 16px;
                                    margin-bottom: 10px;
                                }

                                .desc {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }

                &.typ-gender {
                    .form-check {

                        .form-check-label {
                            input {

                                &:checked+div.radio-label {

                                    i {
                                        &.language {}
                                    }
                                }
                            }

                            .radio-label {

                                &::before {}

                                &::after {}
                            }
                        }
                    }
                }

                &.typ-timeline {

                    .form-check {

                        .form-check-label {
                            .check-box-label {

                                .circle-box {

                                    .circle {}
                                }

                                .radio-txt {}
                            }

                            input {

                                &:checked+div.check-box-label {

                                    .circle-box {
                                        .circle {

                                            &::after {}
                                        }
                                    }
                                }
                            }
                        }

                        &.last-check {

                            .form-check-label {
                                .check-box-label {

                                    .circle {

                                        &::after {}
                                    }

                                    .radio-txt {}
                                }
                            }
                        }
                    }

                    &::before {}
                }

                &.typ-normal {

                    .form-check {

                        .form-check-label {
                            .radio-label {}
                        }
                    }
                }
            }
        }

        &.typ-list {

            .radio-box {

                .form-check {
                    width: 100%;

                    .form-check-label {
                        .check-box-label {

                            .circle-box {
                                min-height: 27px;

                                .circle {
                                    margin-bottom: 0;
                                    margin-right: 0;
                                }
                            }

                            .radio-txt {}
                        }
                    }
                }
            }
        }

        .small {
            font-size: 12px;
        }
    }
}