.bs-tooltip {
    .tooltip-click {
        margin-bottom: 40px;

        a {
            color: $clr-yellow;
            font-weight: $font-medium;

            .icon {
                margin-left: 5px;
                cursor: pointer;

                &:hover {
                    color: $clr-yellow;
                }
            }

            &:hover {
                color: $clr-yellow;
            }
        }
    }

    #registerTip {
        background-color: $clr-white !important;
        color: $clr-black !important;
        max-width: 287px !important;
        padding: 15px !important;
        border: 1px solid $clr-yellow;
        margin-left: 15px !important;
        border-radius: 8px;

        .tooltip-content {
            p {
                margin-bottom: 0;
                color: $clr-black2;
            }
        }

        &::before {
            left: -1px;
            top: 51%;
            height: 20px;
        }

        &::after {
            background-color: $clr-white !important;
            border-top: 1px solid $clr-yellow;
            border-right: 1px solid $clr-yellow;
            left: -9px;
            top: 49%;
            width: 16px;
            height: 16px;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-tooltip {
        .tooltip-click {

            a {
                font-size: 12px;

                .icon {

                    &:hover {}
                }

                &:hover {}
            }
        }

        #registerTip {

            .tooltip-content {
                p {}
            }

            &::before {
                // top: 80%;
                // left: 64%;
                // width: 20px;
                display: none;
            }

            &::after {
                left: 63%;
                top: 91%;
            }
        }
    }
}