.bs-spinner {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgba($color: $clr-black, $alpha: .3);
    z-index: 1;
    display: none;
    .spinner-border{
        color: $clr-green;
    }
    &.show{
        display: flex;
    }
}