.bs-progressbar{
    width: 100%;
    .progress{
        height: 10px;
        margin: 0px;
        margin-bottom: 0;
        background-color: rgba($color: $clr-light-green, $alpha: 0.2);
        overflow: visible;
        border-radius: 15px;
        width: 100%;
        .progress-bar{
            background-color: $clr-green;
            position: relative;
            overflow: visible;
            border-radius: 15px;
            width: 100%;
            &::before{
                content: "\e902";
                font-family: 'icomoon';
                font-size: 16px;
                position: absolute;
                top: -25px;
                right: -8px;
                color: $clr-green;
            }
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-progressbar{
        .progress{
            margin: 0;
            .progress-bar{
                &::before{
                }
            }
        }
    }
}