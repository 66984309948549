@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?fwshb4');
  src:  url('../../fonts/icomoon.eot?fwshb4#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?fwshb4') format('truetype'),
    url('../../fonts/icomoon.woff?fwshb4') format('woff'),
    url('../../fonts/icomoon.svg?fwshb4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-heart-disease-input:before {
  content: "\e92c";
}
.icon-obesity-input:before {
  content: "\e92d";
}
.icon-cholestorl-input:before {
  content: "\e92e";
}
.icon-diabetes-input:before {
  content: "\e92f";
}
.icon-beat:before {
  content: "\e928";
  color: #1e986d;
}
.icon-doctor:before {
  content: "\e929";
  color: #1e986d;
}
.icon-finger:before {
  content: "\e92a";
  color: #1e986d;
}
.icon-heart2:before {
  content: "\e92b";
  color: #1e986d;
}
.icon-clock:before {
  content: "\e927";
  color: #1e986d;
}
.icon-tooltip:before {
  content: "\e926";
}
.icon-meditation:before {
  content: "\e925";
}
.icon-disease-education:before {
  content: "\e920";
}
.icon-exersice:before {
  content: "\e921";
}
.icon-videos:before {
  content: "\e922";
}
.icon-discount:before {
  content: "\e923";
}
.icon-test-consultation:before {
  content: "\e924";
}
.icon-medicines:before {
  content: "\e91f";
}
.icon-minus-table:before {
  content: "\e91d";
  color: #6a6a6a;
}
.icon-right-table:before {
  content: "\e91e";
  color: #1e986d;
}
.icon-rest-sleep:before {
  content: "\e91c";
}
.icon-blood-sugar-vitals:before {
  content: "\e90f";
}
.icon-bp-vitals:before {
  content: "\e910";
}
.icon-cholesterol-vitals:before {
  content: "\e911";
}
.icon-diet:before {
  content: "\e912";
}
.icon-disease-history:before {
  content: "\e913";
}
.icon-excercise:before {
  content: "\e914";
}
.icon-lifestyle:before {
  content: "\e915";
}
.icon-medicine-history:before {
  content: "\e916";
}
.icon-smoking:before {
  content: "\e917";
}
.icon-stress:before {
  content: "\e918";
}
.icon-weight:before {
  content: "\e919";
}
.icon-age:before {
  content: "\e91a";
}
.icon-alcohol:before {
  content: "\e91b";
}
.icon-plus:before {
  content: "\e90e";
  color: #fff;
}
.icon-yt-play:before {
  content: "\e90d";
  color: #fff;
}
.icon-diabetes:before {
  content: "\e90a";
  color: #6a6a6a;
}
.icon-iron-man:before {
  content: "\e90b";
  color: #6a6a6a;
}
.icon-band-aid:before {
  content: "\e90c";
  color: #6a6a6a;
}
.icon-history:before {
  content: "\e907";
  color: #6a6a6a;
}
.icon-diagonisis:before {
  content: "\e908";
  color: #6a6a6a;
}
.icon-heart-disease:before {
  content: "\e909";
  color: #6a6a6a;
}
.icon-check-close:before {
  content: "\e906";
  color: #f29e4a;
}
.icon-prev-arrow:before {
  content: "\e905";
}
.icon-female:before {
  content: "\e903";
}
.icon-male:before {
  content: "\e904";
  color: #fff;
}
.icon-heart:before {
  content: "\e902";
  color: #1e986d;
}
.icon-right:before {
  content: "\e901";
  color: #595454;
}
.icon-next-arrow:before {
  content: "\e900";
  color: #fff;
}
