.bs-card {
    background-color: $clr-white;
    padding: 50px;
    padding-left: 0;
    border-radius: 10px;
    box-shadow: 0px 4px 12px $clr-box-shadow;
    min-height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    width: 100%;
    transition: all 0.3s;
    // transform: translate(0%,0%);
    position: relative;
    --bs-gutter-x: 0;

    &.typ-discount-new {
        box-shadow: none;
        background-color: $clr-light-blue4;
        display: block;
        padding: 15px;
        position: relative;
        text-align: left;
        width: 49%;
        height: fit-content;

        .main-title {
            font-family: $fnt1;
            font-size: 32px;
        }

        .discount {
            position: absolute;
            top: -15px;
            right: 32px;
            background: linear-gradient(97.57deg, #93F9B9 -37.38%, #1D976C 94.13%);
            border-radius: 12px;
            color: $clr-white;
            padding: 17px 22px;
            font-size: 24px;
            font-weight: $font-bold;
            margin-bottom: 0;
        }

        .inner-row {
            display: flex;
            gap: 15px;
            margin-bottom: 15px;

            .left-col {
                display: flex;
                align-items: flex-start;
                width: 75%;

                .icon {
                    font-size: 40px;
                    margin-right: 15px;
                }

                .icn-txt {
                    .title {
                        color: $clr-green;
                        font-weight: $font-medium;
                        font-size: 16px;
                        text-transform: uppercase;
                        margin-bottom: 0;
                    }

                    .desc {
                        font-size: 12px;
                        color: $clr-black2;
                        margin-bottom: 0;
                    }
                }
            }

            .right-col {
                text-align: center;

                .price {
                    font-size: 24px;
                    margin-bottom: 0;
                    font-weight: $font-bold;

                    &::before {
                        content: '₹';
                    }
                }

                .mrp {
                    text-decoration: line-through;
                    color: $clr-grey6;
                    font-weight: $font-semibold;
                    font-size: 16px;
                    margin-bottom: 0;

                    &::before {
                        content: '₹';
                    }

                    &::after {
                        content: '/-';
                    }
                }
            }
        }

        .coupon-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .therapy {
                font-size: 14px;
                margin-bottom: 0;
            }

            .coupon-code {
                min-width: 174px;
                text-align: center;
                margin-bottom: 0;
                padding: 15px;
                border-radius: 8px;
                border: 1px solid $clr-green;
                font-weight: $font-bold;
                font-size: 20px;
                color: $clr-green;
                min-width: 174px;
            }
        }
    }

    &.typ-pl-pr-add {
        padding-left: 150px;
        padding-right: 150px;
    }

    &.typ-border {
        margin-top: 50px;
        box-shadow: none;
        border: 1px solid $clr-border;
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 40px;
    }

    &.typ-subscription-main {
        flex-direction: column;

        &.typ-video-player {
            padding-top: 70px;
        }
    }

    &>.row {
        min-width: 100%;
        width: 100%;
        --bs-gutter-x: 0;

        .col-md-12 {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &.typ-pl-on {
        padding-left: 50px;
    }

    &.typ-popup {
        width: 575px;
        min-height: inherit;
        height: auto;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 20px 15px;
        display: block;
        max-height: 100vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        &.typ-video {
            width: 80%;
            padding-top: 50px;
            background-color: transparent;

            iframe {}

            .close-popup {
                .icon {
                    &::before {
                        color: $clr-white;
                    }
                }
            }
        }
    }

    .close-popup {
        position: absolute;
        top: 20px;
        right: 15px;
        cursor: pointer;

        .icon {
            &::before {
                color: $clr-black2;
            }
        }
    }

    &.typ-mb-on {
        margin-bottom: 70px;
    }

    &.typ-mb-on-30{
        margin-bottom: 30px;
    }

    &.typ-height-auto {
        min-height: inherit;
    }

    &.typ-icn-txt {
        box-shadow: none;
        border: 1px solid $clr-border;
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        padding: 30px 15px 15px 15px;

        img {
            width: 56px;
            margin-bottom: 25px;
        }

        .desc {
            text-align: center;
            font-size: 16px;
            font-weight: $font-medium;
            min-height: 72px;
            margin-bottom: 0;
        }
    }

    &.typ-subscription {
        border: none;

        &:hover {
            .icon {
                border: 2px solid $clr-green;

                &.icon-meditation {
                    transform: scale(1.5);
                }
            }

            .avatar {
                border: 2px solid $clr-green;
                border-image: $clr-green;
            }
            .clickHere{
           
            }
        }

        .avatar {
            width: 100%;
            border: 2px solid $clr-light-blue;
            box-shadow: 0px 0px 10px rgba($color: $clr-black, $alpha: 0.1);
            border-radius: 20px;
        }

        .icon {
            font-size: 60px;
            margin-bottom: 25px;
            color: $clr-green;
            font-weight: $font-medium;
            box-shadow: none;
            border: 2px solid $clr-border;
            border-radius: 8px;
            flex-direction: column;
            justify-content: center;
            padding: 36px 0;
            width: 100%;

            // &.icon-meditation {
            //     transform: scale(1.5);
            // }
        }

        .desc {
            min-height: auto !important;
        }
        
        .clickHere{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0;
            color: $clr-green;
            }

        &.typ-subscription-list {
            padding: 0;
            cursor: pointer;
            overflow: hidden;
            margin-bottom: 30px;

            a {
                width: 100%;

                .yt-thumbnail {
                    width: 100%;
                    height: 200px;
                    position: relative;

                    .icon {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 15px;
                        padding: 15px;
                        border: 2px solid $clr-white;
                        border-radius: 50%;
                    }
                }

                .video-title {
                    padding: 15px;
                    text-align: left;
                    margin-bottom: 0;
                    min-height: 102px;
                }
            }
        }

        &.typ-discount-coupon {
            border: none;
            padding: 0;
            border-radius: 10px;
            overflow: hidden;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }
    }

    a {
        color: $clr-black2;
        text-decoration: none;

        &.bs-btn {
            color: $clr-white;
        }
    }

    &.typ-subscription-main {}
}

@media only screen and (max-width: $desktop-breakpoint) {
    .bs-card {
        min-height: calc(100vh - 232px);

        &.typ-icn-txt {
            img {}

            .desc {
                min-height: 120px;
            }
        }

        &.typ-pl-pr-add {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-card {
        padding: 50px;
        min-height: inherit;
        align-items: flex-start;
        overflow: hidden;
        min-width: 100%;

        .row {
            min-width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        &.typ-popup {
            width: 100%;
            top: inherit;
            left: inherit;
            bottom: 0;
            transform: translate(0%, 0%);
        }

        &.typ-pl-on {
            padding-left: 50px;
        }

        &.typ-mob-height-auto {
            min-height: inherit;
        }

        &.typ-icn-txt {
            align-items: center;

            .desc {
                min-height: inherit;
            }
        }

        &.typ-discount-new {
            overflow: visible;

            .main-title {}

            .discount {}

            .inner-row {

                .left-col {

                    .icon {}

                    .icn-txt {
                        .title {}

                        .desc {}
                    }
                }

                .right-col {

                    .price {

                        &::before {}
                    }

                    .mrp {
                        &::before {}

                        &::after {}
                    }
                }
            }

            .coupon-row {

                .therapy {}

                .coupon-code {}
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-card {
        padding: 15px;
        padding-top: 35px;
        min-height: calc(100vh - 232px);
        align-items: flex-start;
        overflow: hidden;

        &.typ-subscription {
            .icon{
                margin-bottom: 15px;
            }
        }

        &.typ-discount-new {
            padding-top: 35px;

            .main-title {
                font-size: 22px;
            }

            .discount {
                top: -15px;
                right: inherit;
                left: 50%;
                transform: translate(-50%, -0%);
                padding: 12px 20px;
                font-size: 16px;
            }

            .inner-row {

                .left-col {

                    .icon {
                        font-size: 30px;
                    }

                    .icn-txt {
                        .title {
                            font-size: 14px;
                        }

                        .desc {}
                    }
                }

                .right-col {

                    .price {
                        font-size: 20px;

                        &::before {}
                    }

                    .mrp {
                        font-size: 14px;

                        &::before {}

                        &::after {}
                    }
                }
            }

            .coupon-row {

                .therapy {
                    font-size: 12px;
                }

                .coupon-code {
                    padding: 10px;
                    font-size: 14px;
                    line-height: 1;
                    min-width: 130px;
                }
            }
        }

        &.typ-score-analysis {
            padding-top: 30px !important;
            padding-bottom: 30px !important;
        }

        &.typ-subscription-main {
            padding-top: 50px;
        }

        &.typ-thank-you {
            display: flex;
            align-items: center;
        }

        &.typ-price-table {
            padding: 15px;
        }

        .close-popup {
            .icon {
                font-size: 20px;
            }
        }

        .row {
            // width: fit-content;  
        }

        &.typ-popup {
            width: 90%;
            top: 50%;
            left: 50%;
            bottom: inherit;
            transform: translate(-50%, -50%);

            &.typ-video {

                iframe {
                    height: 210px !important;
                }

                .close-popup {
                    .icon {
                        &::before {}
                    }
                }
            }
        }

        &.typ-pl-on {
            padding-left: 15px;
        }

        &.typ-mob-height-auto {
            min-height: inherit;
        }

        &.typ-mb-on {
            margin-bottom: 25px;
        }

        &.typ-icn-txt {
            .desc {
                font-size: 14px;
            }
        }

        &.typ-pl-pr-add {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}