.bs-player {
    margin-bottom: 20px;

    &.typ-popup {
        cursor: pointer;
        width: 100%;

        picture {
            width: 100%;
            display: inline-block;
            position: relative;

            img {
                width: 100%;
                border-radius: 6px;
            }

            .icon {
                position: absolute;
                width: 100px;
                height: 100px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 55px;
                background-color: rgba($color: $clr-grey6, $alpha: 0.3);
                border-radius: 50%;
                padding: 25px;

                &::before {
                    color: $clr-black;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin-left: 4px;
                }
            }
        }

        .player-content {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            flex-direction: column;

            picture {
                img {}
            }

            .icon {
                position: relative;
                transform: none;
                top: 0;
                left: 0;
                background-color: rgba($color: $clr-white, $alpha: 0.4);

                &::before {}
            }
        }
    }

    &.typ-overlay {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: $clr-black, $alpha: 0.6);
            z-index: 1;
            border-radius: 6px;
        }
    }

    &.typ-list {
        border: 2px solid $clr-light-blue;
        box-shadow: 0px 0px 10px rgba($color: $clr-black, $alpha: 0.1);
        border-radius: 20px;
        display: flex;
        padding: 15px 30px;
        gap: 50px;
        text-align: left;
        cursor: pointer;
        margin-bottom: 35px;

        &:hover {
            border: 2px solid $clr-green;
            border-image: $clr-green;

            .left-col {
                .title {
                    color: $clr-green;
                }

                .desc {}
            }
        }

        .left-col {
            width: 80%;

            .title {
                font-size: 32px;
                font-family: $fnt1;
                margin-bottom: 12px;
            }

            .desc {
                font-size: 20px;
                margin-bottom: 0;
                color: $clr-grey6;
                min-height: 60px;
            }
        }

        .right-col {
            width: 20%;

            picture {
                width: 100%;
                display: inline-block;
                position: relative;
                border: 1px solid $clr-border;
                border-radius: 8px;
                overflow: hidden;

                img {
                    width: 100%;
                    border-radius: 6px;
                }

                .icon {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 20px;
                    background-color: rgba($color: $clr-white, $alpha: 0.3);
                    border-radius: 50%;
                    padding: 25px;

                    &::before {
                        color: $clr-black;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin-left: 2px;
                    }
                }
            }
        }

        // &:last-child {
        //     margin-bottom: 0;
        // }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-player {

        &.typ-list {
            flex-direction: column-reverse;
            gap: 15px;
            padding: 15px;

            &:hover {

                .left-col {
                    .title {}

                    .desc {}
                }
            }

            .left-col {
                width: 100%;

                .title {
                    font-size: 28px;
                }

                .desc {
                    font-size: 16px;
                }
            }

            .right-col {
                width: 100%;

                picture {

                    img {}

                    .icon {

                        &::before {}
                    }
                }
            }

            &:last-child {}
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-player {
        &.typ-popup {
            picture {
                img {}

                .icon {
                    padding: 0;
                    width: 50px;
                    height: 50px;
                    font-size: 30px;

                    &::before {
                        margin-left: 3px;
                    }
                }
            }
        }

        &.typ-list {

            &:hover {

                .left-col {
                    .title {}

                    .desc {}
                }
            }

            .left-col {

                .title {
                    font-size: 20px;
                }

                .desc {
                    font-size: 12px;
                }
            }

            .right-col {

                picture {

                    img {}

                    .icon {

                        &::before {}
                    }
                }
            }

            &:last-child {}
        }
    }
}