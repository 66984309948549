.bs-drag {
    ul {
        list-style-type: none;
        gap: 15px;
        display: grid;
        padding-left: 0;

        li {
            padding: 15px;
            border: 1px solid $clr-green;
            border-radius: 8px;
            font-size: 16px;
            display: flex;
            align-items: center;
            line-height: 1;

            .icon {
                margin-right: 10px;
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-drag {
        ul {

            li {
                font-size: 12px;
                .icon {
                }
            }
        }
    }
}