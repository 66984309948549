.bs-popup {
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    .popup-img {
        width: 100%;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 3.58px solid $clr-white;
        padding: 20px;
        border-radius: 50%;
        font-size: 20px;

        &::before {
            position: absolute;
            top: 55%;
            left: 55%;
            transform: translate(-50%, -50%);
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $clr-black, $alpha: 0.4);
    }

    &.typ-max-wid {
        max-width: 400px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-popup {
        margin-bottom: 20px;

        .popup-img {}

        .icon {

            &::before {}
        }

        &::before {}

        &.typ-max-wid {
            margin-bottom: 20px;
        }
    }
}