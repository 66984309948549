.modal {

    .modal-dialog {
        max-width: 850px;

        .bs-modal {
            position: relative;

            .close {
                position: absolute;
                right: 0;
                top: 0;
                background-color: transparent;
                border-radius: 0;
                border: none;
                color: $clr-black;
                line-height: 0;
                padding: 15px;
            }

            .cm-para {
                .pl-15 {
                    padding-left: 15px;
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .modal {

        .modal-dialog {
            .modal-body{
                padding-top: 30px;
            }

            .bs-modal {

                .close {
                    width: 15px;
                    height: 15px;
                    border: 1px solid $clr-black2;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    border-radius: 50%;
                    right: -10px;
                    top: -24px;
                }
            }
        }
    }
}