.bs-header {
    text-align: center;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .logo-box{
        width: 20%;
        margin-right: 15px;
        text-align: left;
        img{
            width: 130px;
        }
    }
    .bs-progressbar{
        width: 50%;
    }
    img{
        width: 90px;
    }
    &.typ-2{
        justify-content: center;
        .logo-2{
            display: none;
        }
    }
}

@media only screen and (max-width: $desktop-breakpoint) {
    .bs-header {
        padding: 15px 0; 
        padding-top: 20px;
        .logo-box{
            img{
                width: 100%;
            }
        }
        .bs-progressbar{
            margin-bottom: -10px;
        }
        &.typ-2{
            justify-content: space-between;
            .logo-2{
                display: block;
            }
        }
    }
}
