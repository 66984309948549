.bs-content{
    p{
        font-size: 16px;
        background: transparent;
        border: none;
        outline: none;
        background: transparent;
        border: none;
        outline: none;
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    a{
        color: $clr-green;
    }
    ul{
        margin-bottom: 15px;
        li{
            margin-bottom: 0;
        }
    }
    ol{
        margin-bottom: 15px;
        li{
            &>ul{
                margin-top: 15px;
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-content{
        p{
            font-size: 12px;
            &:last-child{
            }
        }
        a{
    
        }
        ul{
            li{
                font-size: 12px;
            }
        }
        ol{
            li{
                font-size: 12px;
                &>ul{
                }
            }
        }
    }
}