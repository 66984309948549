.bs-img-sprite {
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;

    &.typ-exersice {
        width: 235px;
        height: 300px;
        background: url('../../images/sprites/exersice.png') 0px 0px;
    }

    &.typ-meditation {
        width: 300px;
        height: 300px;
        background: url('../../images/sprites/meditation.png') 0px 0px;
    }

    &.typ-disease {
        width: 300px;
        height: 300px;
        background: url('../../images/sprites/disease.png') 0px 0px;
    }

    &.typ-select-language {
        width: 427px;
        height: 400px;
        background: url('../../images/sprites/cm-sprite.png') -11163px -10px;
    }

    &.typ-select-patient {
        width: 200px;
        height: 400px;
        background: url('../../images/sprites/cm-sprite.png') -2460px -10px;
    }

    &.typ-general-details {
        width: 192px;
        height: 400px;
        background: url('../../images/sprites/cm-sprite.png') -1188px -10px;
    }

    &.typ-general-details2 {
        width: 192px;
        height: 400px;
        background: url('../../images/sprites/cm-sprite.png') -1188px -10px;

        &.Male {
            &.bg-1 {
                width: 192px;
                height: 400px;
                background: url('../../images/sprites/cm-sprite.png') -1188px -10px;
            }

            &.bg-2 {
                background: url('../../images/sprites/cm-sprite.png') -1400px -10px;
            }

            &.bg-3 {
                background: url('../../images/sprites/cm-sprite.png') -1612px -10px;
            }
        }

        &.Female {
            &.bg-1 {
                width: 192px;
                height: 400px;
                background: url('../../images/sprites/cm-sprite.png') -1824px -10px;
            }

            &.bg-2 {
                background: url('../../images/sprites/cm-sprite.png') -2036px -10px;
            }

            &.bg-3 {
                background: url('../../images/sprites/cm-sprite.png') -2248px -10px;
            }
        }
    }

    &.typ-lifestyle-assessment {
        &.bg-1 {
            width: 428px;
            height: 400px;
            background: url('../../images/sprites/cm-sprite.png') -11610px -10px;
        }

        &.bg-2 {
            width: 190px;
            height: 400px;
            background: url('../../images/sprites/cm-sprite.png') -768px -10px;
        }

        &.bg-3 {
            width: 190px;
            height: 400px;
            background: url('../../images/sprites/cm-sprite.png') -978px -10px;
        }
    }

    &.typ-lifestyle-assessment2 {
        &.bg-1 {
            width: 380px;
            height: 400px;
            background: url('../../images/sprites/cm-sprite.png') -6338px -10px;
        }

        &.bg-2 {
            width: 214px;
            height: 400px;
            background: url('../../images/sprites/cm-sprite.png') -2913px -10px;
        }
    }

    &.typ-bmi {
        width: 381px;
        height: 400px;
        background: url('../../images/sprites/cm-sprite.png') -9138px -10px;
    }

    &.typ-disease-details {
        width: 214px;
        height: 400px;
        background: url('../../images/sprites/cm-sprite.png') -3147px -10px;
    }

    &.typ-medication-details {
        width: 381px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -9539px -10px;
    }

    &.typ-sugar-values {
        width: 380px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -6738px -10px;
    }

    &.typ-heart-disease {
        width: 381px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -9940px -10px;
    }

    &.typ-hafway-done {
        width: 161px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -191px -10px;
    }

    &.typ-cholesterol {
        width: 380px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -7138px -10px;
    }

    &.typ-blood-pressure {
        width: 380px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -7538px -10px;
    }

    &.typ-stress {
        width: 381px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -10341px -10px;
    }

    &.typ-waist {
        width: 374px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -5944px -10px;
    }

    &.typ-height {
        width: 401px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -10742px -10px;
    }

    &.typ-band-girl {
        &.Male {
            &.bg-1 {
                width: 194px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -830px -0;
                width: 162px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -317px -0;
            }

            &.bg-2 {
                width: 156px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -0 -0;
            }

            &.bg-3 {
                width: 194px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -830px -0;
            }

            &.bg-4 {
                width: 224px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -1431px -0;
            }

            &.bg-5 {
                width: 196px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -1024px -0;
            }
        }

        &.Female {
            &.bg-1 {
                width: 178px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -652px -0;
            }

            &.bg-2 {
                width: 161px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -156px -0;
            }

            &.bg-3 {
                width: 173px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -479px -0;
            }

            &.bg-4 {
                width: 262px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -1655px -0;
            }

            &.bg-5 {
                width: 211px;
                height: 275px;
                background: url('../../../assets/images/sprites/report-sprites.png') -1220px -0;
            }
        }
    }

    &.typ-symtom-situations {
        width: 166px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -372px -10px;
    }

    &.typ-otp-verification {
        width: 380px;
        height: 400px;
        background: url('../../../assets/images/sprites/cm-sprite.png') -7950px -10px;
    }

    &.typ-mb-on {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: $desktop-breakpoint) {
    .bs-img-sprite {
        &.typ-select-language {
            width: 200px;
            height: 200px;
        }

        &.typ-band-girl {
            &.Male {
                &.bg-1 {
                    width: 80px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -1px -1px;
                }

                &.bg-2 {
                    width: 85px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -83px -1px;
                }

                &.bg-3 {
                    width: 105px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -455px -1px;
                }

                &.bg-4 {
                    width: 122px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -788px -1px;
                }

                &.bg-5 {
                    width: 107px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -562px -1px;
                }
            }

            &.Female {
                &.bg-1 {
                    width: 97px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -356px -1px;
                }

                &.bg-2 {
                    width: 88px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -170px -1px;
                }

                &.bg-3 {
                    width: 94px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -260px -1px;
                }

                &.bg-4 {
                    width: 143px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -912px -1px;
                }

                &.bg-5 {
                    width: 115px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -671px -1px;
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-img-sprite {
        margin-bottom: 15px;

        &.typ-exersice {
            width: 110px;
            height: 140px;
            background: url('../../images/sprites/exersice-mob.png') 0px 0px;
        }

        &.typ-select-language {
            width: 160px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -3193px -0;
        }

        &.typ-mob-no-bg {
            display: none;
        }

        &.typ-general-details {
            width: 72px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -395px -0;
        }

        &.typ-general-details2 {
            width: 72px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -395px -0;

            &.Male {
                &.bg-1 {
                    width: 72px;
                    height: 150px;
                    background: url('../../images/sprites/cm-sprite-mob.png') -395px -0;
                }

                &.bg-2 {
                    width: 72px;
                    height: 150px;
                    background: url('../../images/sprites/cm-sprite-mob.png') -467px -0;
                }

                &.bg-3 {
                    width: 72px;
                    height: 150px;
                    background: url('../../images/sprites/cm-sprite-mob.png') -539px -0;
                }
            }

            &.Female {
                &.bg-1 {
                    width: 72px;
                    height: 150px;
                    background: url('../../images/sprites/cm-sprite-mob.png') -611px -0;
                }

                &.bg-2 {
                    width: 72px;
                    height: 150px;
                    background: url('../../images/sprites/cm-sprite-mob.png') -683px -0;
                }

                &.bg-3 {
                    width: 72px;
                    height: 150px;
                    background: url('../../images/sprites/cm-sprite-mob.png') -755px -0;
                }
            }
        }

        &.typ-lifestyle-assessment {
            &.bg-1 {
                width: 160px;
                height: 150px;
                background: url('../../images/sprites/cm-sprite-mob.png') -3353px -0;
            }

            &.bg-2 {
                width: 71px;
                height: 150px;
                background: url('../../images/sprites/cm-sprite-mob.png') -253px -0;
            }

            &.bg-3 {
                width: 71px;
                height: 150px;
                background: url('../../images/sprites/cm-sprite-mob.png') -324px -0;
            }
        }

        &.typ-lifestyle-assessment2 {
            &.bg-1 {
                width: 80px;
                height: 150px;
                background: url('../../images/lifestyle-assessment/sprite-2-mob.png') -10px -10px;
            }

            &.bg-2 {
                width: 80px;
                height: 150px;
                background: url('../../images/lifestyle-assessment/sprite-2-mob.png') -110px -10px;
            }
        }

        &.typ-bmi {
            width: 160px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -3673px -0;
        }

        &.typ-disease-details {
            width: 80px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -907px -0;
        }

        &.typ-medication-details {
            width: 160px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -3833px -0;
        }

        &.typ-sugar-values {
            width: 130px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -1960px -0;
        }

        &.typ-heart-disease {
            width: 160px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -3993px -0;
        }

        &.typ-hafway-done {
            width: 120px;
            height: 300px;
            background-image: url('../../../assets/images/halfway-done/avatar-1.png');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &.typ-cholesterol {
            width: 130px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -2090px -0;
        }

        &.typ-blood-pressure {
            width: 130px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -2220px -0;
        }

        &.typ-stress {
            width: 150px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -2893px -0;
        }

        &.typ-waist {
            width: 140px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -2610px -0;
        }

        &.typ-height {
            width: 150px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -3043px -0;
        }

        &.typ-symtom-situations {
            width: 62px;
            height: 150px;
            background: url('../../images/sprites/cm-sprite-mob.png') -120px -0;
        }

        &.typ-band-girl {
            &.Male {
                &.bg-1 {
                    width: 80px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -1px -1px;
                }

                &.bg-2 {
                    width: 85px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -83px -1px;
                }

                &.bg-3 {
                    width: 105px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -455px -1px;
                }

                &.bg-4 {
                    width: 122px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -788px -1px;
                }

                &.bg-5 {
                    width: 107px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -562px -1px;
                }
            }

            &.Female {
                &.bg-1 {
                    width: 97px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -356px -1px;
                }

                &.bg-2 {
                    width: 88px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -170px -1px;
                }

                &.bg-3 {
                    width: 94px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -260px -1px;
                }

                &.bg-4 {
                    width: 143px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -912px -1px;
                }

                &.bg-5 {
                    width: 115px;
                    height: 150px;
                    background: url('../../images/sprites/report-sprites-mob.png') -671px -1px;
                }
            }
        }

        &.typ-otp-verification {
            width: 130px;
            height: 150px;
            background: url('../../../assets/images/sprites/cm-sprite-mob.png') -2350px -10px;
        }
    }
}