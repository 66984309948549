.bs-heading {
    max-width: 550px;
    margin: 0 auto;

    .heading {
        font-family: $fnt1;
        font-size: 32px;
        color: $clr-black;
        margin-bottom: 10px;
        line-height: 1.4;

        &.mb-40 {
            margin-bottom: 40px;
        }

        &.typ-max-width{
            max-width: 300px;
            margin: 0 auto;
        }
    }

    .subheading {
        font-size: 18px;
        color: $clr-grey;
        margin-bottom: 50px;

        &.mb-20 {
            margin-bottom: 20px;
        }

        &.typ-20 {
            font-size: 20px;
        }

        &.typ-20-medium {
            font-size: 20px;
            font-weight: $font-medium;
            color: $clr-black2;
        }
        span{
            &.coupon-code{
                font-weight: $font-bold;
            }
            &.blcok{
                display: block;
            }
        }
    }

    .green{
        font-size: 20px;
        font-family: $fnt1;
        margin-bottom: 40px;
        color: $clr-green;
    }

    &.typ-center {
        text-align: center;
    }

    &.typ-full-width {
        max-width: inherit;
    }

    &.typ-thank-you {
        max-width: 70%;

        .heading {
            font-size: 120px;
            line-height: 1;
            margin-bottom: 50px;
        }

        .subheading {}
    }

    &.mb-0{
        margin-bottom: 0;
        .heading{
            margin-bottom: 0;

            &.mb-40 {
                margin-bottom: 40px;
            }
        }
    }
    &.typ-mt-on{
        margin-top: 50px;
    }

    &.typ-white{
        .heading{
            color: $clr-white;
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-heading {
        max-width: 100%;

        .heading {
            font-size: 28px;
            margin-bottom: 0px;
        }

        .subheading {
            font-size: 14px;

            &.typ-20-medium {
                font-size: 15px;
            }
        }

        &.typ-mob-center {
            text-align: center;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-heading {
        .heading {
            font-size: 20px;
            margin-bottom: 10px;

            &.mb-40 {
                margin-bottom: 20px;
            }
        }

        .subheading {
            margin-bottom: 30px;
            font-size: 13px;

            &.typ-20 {
                font-size: 13px;
            }

            &.mb-40 {
                margin-bottom: 20px;
            }
            &.typ-lottie{
                margin-bottom: 0;
            }
        }

        .green{
            font-size: 12px;
            margin-bottom: 20px;
        }

        &.typ-thank-you {
            max-width: 100%;
    
            .heading {
                font-size: 50px;
                margin-bottom: 25px;
            }
    
            .subheading {}
        }
    }
}