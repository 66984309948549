.bs-breadcrumb {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-breadcrumb {
        left: 15px;
        top: 15px;
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-breadcrumb {
        left: 15px;
        top: 15px;

        .breadcrumb {
            .breadcrumb-item {
                font-size: 12px;
                a {
                    font-size: 12px;
                }

                a {
                    font-size: 12px;
                }
            }
        }
    }
}