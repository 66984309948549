.bs-swiper {
    position: relative;

    .slider-title {
        font-size: 20px;
        font-weight: $font-medium;
        margin-bottom: 20px;
    }

    .swiper-parent {
        position: relative;
        background-color: $clr-grey4;
        border-radius: 50px;
        padding: 0 20px;


        &::before {
            content: '';
            height: 120%;
            aspect-ratio: 1/1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba($color: $clr-orange, $alpha: 0.4);
            border-radius: 50%;
            z-index: 2;
        }

        &::after {
            content: '';
            height: 100%;
            aspect-ratio: 1/1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $clr-orange;
            border-radius: 50%;
            z-index: 1;
        }
    }

    .swiper {
        &.age-swiper {
            border-radius: 50px;
            cursor: pointer;
            position: relative;
            z-index: 2;

            .swiper-wrapper {
                z-index: 0;
                .swiper-slide {
                    text-align: center;
                    padding: 0;
                    font-size: 24px;
                    font-weight: $font-medium;
                    border-radius: 50%;
                    z-index: 0;
                    position: relative;
                    aspect-ratio: 1/1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    z-index: -5;
                    margin-bottom: -2px;

                    &.swiper-slide-active {
                        color: $clr-white;
                        position: relative;
                        z-index: 5;
                        font-weight: $font-semibold;
                    }
                }
            }

            // .active-box {
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     transform: translate(-50%,-50%);
            //     width: 100px;
            //     height: 100px;
            //     border-radius: 9999px;
            //     background-color: $clr-orange;
            //     opacity: 0;
            //     z-index: 2;
            //     pointer-events: none;
            //     animation: sonarWave 2s linear infinite;
            // }
        }
    }

    &.mb-40 {
        margin-bottom: 40px;
    }
    &.typ-height{
        position: relative;
        margin-bottom: 50px !important;
        // &::before{
        //     content: 'feet,inches';
        //     position: absolute;
        //     bottom: -40px;
        //     left: 50%;
        //     transform: translate(-50%, -00%);
        //     font-size: 16px;
        // }
    }
    &.typ-weight{
        position: relative;
        margin-bottom: 50px !important;
        &::after{
            content: 'kgs';
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translate(-50%, -00%);
            font-size: 16px;
        }
    }
    &.typ-age{
        position: relative;
        margin-bottom: 50px !important;
        &::after{
            content: 'years';
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translate(-50%, -00%);
            font-size: 16px;
        }
    }
    &.typ-feet{
        position: relative;
        margin-bottom: 50px !important;
        .swiper-wrapper {
            .swiper-slide{
                &::after{
                    content: "'";
                }
            }
        }
        &::after{
            content: 'feet';
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translate(-50%, -00%);
            font-size: 16px;
        }
    }
    &.typ-inch{
        position: relative;
        margin-bottom: 50px !important;
        .swiper-wrapper {
            .swiper-slide{
                position: relative;
                right: -2px;
                &::after{
                    content: '"';
                }
            }
        }
        &::after{
            content: 'inch';
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translate(-50%, -00%);
            font-size: 16px;
        }
    }
}

@-webkit-keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes sonarWave {
    from {
        opacity: 0.4;
    }

    to {
        transform: scale(3);
        opacity: 0;
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-swiper {
        .slider-title {
            margin-bottom: 15px;
        }

        .swiper {
            &.age-swiper {

                .swiper-wrapper {
                    .swiper-slide {

                        padding: inherit;

                        &.swiper-slide-active {}
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-swiper {
        .slider-title {
            font-size: 14px;
        }
        .swiper-parent{
            padding: 0 10px;
        }

        .swiper {
            &.age-swiper {

                .swiper-wrapper {
                    .swiper-slide {
                        font-size: 16px;

                        padding: inherit;

                        &.swiper-slide-active {}
                    }
                }
            }
        }
        &.typ-weight{
            &::after{
                font-size: 12px;
                bottom: -30px;
            }
        }
        &.typ-height{
            &::before{
                font-size: 12px;
                bottom: -30px;
            }
        }
        &.typ-age{
            &::after{
                font-size: 12px;
                bottom: -30px;
            }
        }

        &.mb-40 {
            margin-bottom: 20px;
        }
        &.typ-feet{
            &::after{
                font-size: 12px;
                bottom: -30px;
            }
        }
        &.typ-inch{
            &::after{
                font-size: 12px;
                bottom: -30px;
            }
        }
    }
}