.bs-tabs {
    margin-top: 25px;

    .nav {
        &.nav-tabs {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            gap: 0 15px;
            border-bottom: none;
            margin-bottom: 25px;

            .nav-item {
                padding: 0px;
                text-align: center;
                border: 1px solid transparent;
                width: 100%;
                cursor: pointer;

                a {
                    text-decoration: none;
                    color: $clr-black;
                    padding: 20px;
                    text-align: center;
                    border: 1px solid transparent;
                    width: 100%;
                    display: block;
                    border-radius: 8px;
                    font-size: 16px;
                    font-family: $fnt2;

                    &.typ-red {
                        border-color: $clr-red;

                        &.active {
                            background: $clr-red-gradient;
                            color: $clr-white;
                            border-color: transparent;
                        }
                    }

                    &.typ-yellow {
                        border-color: $clr-yellow2;

                        &.active {
                            background: $clr-yellow-gradient;
                            color: $clr-white;
                            border-color: transparent;
                        }
                    }

                    &.typ-green {
                        border-color: $clr-green2;

                        &.active {
                            background: $clr-green-gradient;
                            color: $clr-white;
                            border-color: transparent;
                        }
                    }
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            &.typ-red {
                .bs-accordion {
                    .accordion {
                        .accordion-item {
                            background-color: $clr-pink;

                            .accordion-header {
                                background-color: $clr-pink;

                                .accordion-button {
                                    background-color: $clr-pink;

                                    &::after {
                                        color: $clr-red3;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.typ-yellow {
                .bs-accordion {
                    .accordion {
                        .accordion-item {
                            background-color: #FFF8EC;

                            .accordion-header {
                                background-color: #FFF8EC;

                                .accordion-button {
                                    background-color: #FFF8EC;

                                    &::after {
                                        color: #FFB228;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.typ-green {
                .bs-accordion {
                    .accordion {
                        .accordion-item {
                            background-color: #E5FFE8;

                            .accordion-header {
                                background-color: #E5FFE8;

                                .accordion-button {
                                    background-color: #E5FFE8;

                                    &::after {
                                        color: #33DE41;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .bs-tabs {
        .nav {
            &.nav-tabs {
                gap: 5px;

                .nav-item {
                    a {
                        font-size: 10px;
                        padding: 8px;
                        min-height: 63px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}