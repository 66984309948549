// fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Epilogue:wght@300;400;500;700&display=swap');
// font-family: 'DM Serif Display', serif;
// font-family: 'Epilogue', sans-serif;

//Color
$clr-white: #ffffff;
$clr-black: #000000;
$clr-black2: #333333;
$clr-grey:#827F7F;
$clr-grey2:#888888;
$clr-grey3:#7B7B7B;
$clr-grey4:#E6E6E6;
$clr-grey5:#B7B7B7;
$clr-grey6:#6A6A6A;
$clr-grey7:#5E5B5B;
$clr-grey8:#444444;
$clr-grey9:#8D8D8D;
$clr-grey10:#CACACA;
$clr-border:#BFBFBF;
$clr-red:#9D2633;
$clr-red2:#DA2F42;
$clr-red3:#FF545D;
$clr-lightblue: #FCFFFA;
$clr-green:#1E986D;
$clr-green2:#ACF1B2;
$clr-light-green: #78FFD6;
$clr-light-green2: #F6FFEF;
$clr-light-blue: #D5ECF4;
$clr-light-blue2: #F9F9F9;
$clr-light-blue3: #EFF9FF;
$clr-light-blue4: #EAF9FF;
$clr-box-shadow: rgba(105,105,105,12%);
$clr-radio-gradient: linear-gradient(100.35deg, #93F9B9 -5.12%, #1D976C 100%);
$clr-radio-gradient-reverse: linear-gradient(100.35deg,#1D976C  -5.12%, #93F9B9 100%);
$clr-red-gradient: linear-gradient(100.35deg, #FF7383 -5.12%, #D61A2E 100%);
$clr-yellow-gradient: linear-gradient(100.35deg, #FFD383 -5.12%, #FFB228 100%);
$clr-green-gradient: linear-gradient(100.35deg, #ACF1B2 -5.12%, #33DE41 100%);
$clr-orange:#FDB668;
$clr-yellow: #F29E4A;
$clr-yellow2: #FFD383;
$clr-yellow-light: #FFF9E8;
$clr-pink: #FFF9FA;
$clr-light-pink: #FFFCEF;
$clr-light-orange: #FFE4D0;


//font family

$fnt1: 'DM Serif Display', serif;
$fnt2: 'Epilogue', sans-serif;

//font weight

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-black: 900;

//breakpoints
$mobile-breakpoint: 767px;
$tablet-breakpoint: 992px;
$desktop-breakpoint: 1024px;
