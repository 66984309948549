.bs-footer {
    text-align: center;
    padding: 20px 0;
    background-color: $clr-lightblue;
    margin-top: 10px;

    .footer-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .quick-links {
            &>a {
                color: $clr-green;
                text-decoration: none;
                padding-right: 10px;
                margin-right: 10px;
                border-right: 1px solid $clr-border;
                font-size: 12px;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                    padding-right: 0;
                    border-right: none;
                }
            }
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .bs-footer {
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 1;

        .footer-row {
            padding-right: 50px;
            padding-left: 50px;

            .quick-links {
                &>a {
                    &:last-child {}
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .mob-d-none {
        .bs-footer {
            display: none;
        }
    }

    .bs-footer {
        margin-top: 10px;
        padding: 10px 0;
        margin-top: 0px;

        .footer-row {
            padding-right: 0px;
            padding-left: 0px;
            flex-direction: column;

            .quick-links {
                display: none;
                &>a {
                    padding-right: 5px;
                    margin-right: 5px;

                    &:last-child {}
                }
            }
        }
    }
}