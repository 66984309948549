.bs-custom-popup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: $clr-black, $alpha: 0.5);
    z-index: 3;
    display: none;
    &.show {
        display: block;
    }
    &.typ-no-bg{
        background-color: transparent   ;
    }
}